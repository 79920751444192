import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"
import { useMedia } from "../../helpers/window"

function FlyingShape ({
  factorX,
  factorY= {sm: 1, md: 1, lg: 1},
  offsetX: offsetXInitial = 0,
  offsetY: offsetYInitial = 0,
  bottom = "auto",
  top = "auto",
  right = "auto",
  left = "auto",
  shape = "circle",
  screens = {xs: 1, sm: 1, md: 1, lg: 1},
  color = "#0017be"
}) {
  const [offsetX, setOffsetX] = useState(offsetXInitial);
  const { name: media } = useMedia();

  const display = typeof factorX[media] !== "undefined" ? screens[media] === 1 : false;

  const handleScroll = () => {
    let _factorX = typeof factorX[media] !== "undefined" ? factorX[media] : 1;
    setOffsetX(Math.round(offsetXInitial - window.pageYOffset * _factorX));
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div>
      { display &&
        <div
          className={styles.shapeWrapper}
          style={{
            top, bottom, left, right, color,
            transform: `translateX(${offsetX}px)`
          }}
        >
          {shape === "circle" &&
          <svg className={styles.circle} viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="50"/>
          </svg>
          }
          {shape === "triangle" &&
          <svg className={styles.triangle} viewBox="0 0 309 250">
            <path d="M154.5,0l154.5,250l-309,0l154.5,-250Z"/>
          </svg>
          }
        </div>
      }
    </div>
  )
}

export default FlyingShape;
