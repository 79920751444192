import scrollToElement from "scroll-to-element";

export function scroller(target, offset = 0, duration = 1000) {
  scrollToElement(target, {
    duration,
    offset
  });
}

export function scrollById(id, offset = 0, duration = 1000) {
  let _id = id[0] === "#" ? document.getElementById(id.slice(1)) : document.getElementById(id);
  scroller(_id, offset, duration);
}
