import React, { useEffect, useRef, useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useStaticQuery, graphql } from "gatsby"
import Logo from "../logo"
import styles from "./navbar.module.scss"
import classnames from "../../helpers/classnames"
import DiscountBanner from "../DiscountBanner"

const buttonStyle = isMenuOpen => {
  return isMenuOpen ? styles.closeButton : styles.menuBurger
}

const Navbar = ({
                  children,
                  isMenuOpen,
                  onToggleMenu,
                  location,
                  logo = "dynamic",
                  fixed = true,
                  hasBackground = false,
                  position = "fixed",
                  discountBanner = null,
                  ...rest
                }) => {
  const ref = useRef(null)
  const { enrolled, rating } = useStaticQuery(query).courseRating
  const [isScrolled, setIsScrolled] = useState(false)

  const getLogoType = () => {
    if (!fixed) {
      return hasBackground ? "white" : "default"
    } else if (logo === "dynamic") {
      return isScrolled ? "default" : "white"
    } else {
      return logo
    }
  }

  const listenScrollEvent = () => {
    setIsScrolled(window.scrollY > 5)
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
    return () => {
      window.removeEventListener("scroll", listenScrollEvent)
    }
  }, [])

  const expires = discountBanner ? new Date(discountBanner.expires) : null
  const showDiscountBanner = discountBanner && ((expires - new Date()) > 0)

  return (
    <div className={classnames(styles.panel)}>

      {showDiscountBanner &&
        <div
          className={classnames(styles.discountBannerWrapper)}
        >
          <DiscountBanner
            expires={expires}
            text={discountBanner.text}
            {...discountBanner}
          />
        </div>
      }

      <header
        ref={ref}
        className={classnames(
          styles.headerWrapper,
          (isScrolled && fixed) ? styles.headerWrapperScrolled : undefined,
          hasBackground ? styles.headerHasBackground : undefined
        )}
        {...rest}
      >
        <div className={styles.innerWrapper}>
          <a href="/#" title="MINDWORX" className={styles.logo}>
            <Logo
              type={getLogoType()}
              style={{ width: "100%", height: "100%" }}
            />
          </a>

          <AnchorLink to={location.pathname + "#reviews"} className={styles.ratingWrapper}>
            <div className={styles.rating}>{rating}</div>
            <div className={styles.subtitle}>{enrolled} students enrolled</div>
          </AnchorLink>

          <button
            type="button"
            onClick={onToggleMenu}
            className={styles.menuButton}
          >
            <div className={buttonStyle(isMenuOpen)}/>
          </button>

          <div
            style={{ display: isMenuOpen ? "block" : "none" }}
            className={classnames(
              isMenuOpen ? styles.navSlide : styles.navSlideBig
            )}
          >
            <nav className={styles.nav}>
              {children}
            </nav>
          </div>
        </div>
      </header>
    </div>
  )
}

const query = graphql`
query CourseRatingNavbar {
  courseRating {
    rating
    enrolled
  }
}`


export default Navbar
