import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styles from './CourseRating.module.scss'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const CourseRating = (props) => {
  const {
    enrolled, rating // Us pricing configuration
   } = useStaticQuery(query)
      .courseRating

  return (
    <div
      className={styles.courseRatingPlugin}
      style={{margin: '40px auto'}}
    >
      <div className={styles.courseRatingPlugin__title}>Avg. Rating</div>
      <div className={styles.courseRatingPlugin__rating}>{rating}</div>
      <div className={styles.courseRatingPlugin__subtitle}>
        {enrolled} students enrolled
      </div>
  </div>
  )
}

const query = graphql`
query CourseRating {
  courseRating {
    rating
    enrolled
  }
}`

export default CourseRating
