import React, {Component} from 'react';

import styles from './reviews.module.scss';
import defaultReviews from "./reviews_default.json"
import avatarMale from "../../images/img/avatar-male.png"
import avatarFemale from "../../images/img/avatar-female.png"
import Button from "../button"

class Reviews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      limit: this.props.limit,
      showMore: true,
    };
  }


  handleShowMore = () => {
    this.setState (state => ({
      showMore: !state.showMore,
      limit: state.limit + this.props.limit,
    }));
  };

  // Store window scroll position after revealing more reaviews
  getSnapshotBeforeUpdate(prevProps, prevState, snapshot) {
    if (prevState.limit !== this.state.limit) {
      return window.scrollY
    }
    return null
  }

  // Update scroll position to correct Y-axis after items are revealed
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const delta = window.scrollY - snapshot // scrolled by re-rendering
      // we should go back by delta.
      window.scrollBy(0, -delta)
    }
  }
  render () {
    const reviews = Array.from(this.props.reviews).slice(0, this.state.limit);

    function getRatingStarsClassName(rating) {
      switch(rating) {
        case 4:
          return styles.ratingFour;
        default:
          return styles.ratingFive;
      }
    }

    function getFlagImg(country) {
      let slugged = country.toLowerCase().replace(/\s+/g, '-');
      return require(`../../images/img/flags/${slugged}.png`);
    }

    return (
      <div className="reviews">
        <ul className={styles.list}>
          {reviews.map ((review, index) => (
            <li key={`xyz${index}`} className={styles.item}>
              <div className={styles.container}>
                <div className={getRatingStarsClassName(review.rating)}/>

                <div className={styles.informationWrapper}>
                  <img
                    width="40px"
                    height="48px"
                    src={review.gender === "male" ? avatarMale : avatarFemale}
                    alt={`avatar ${review.gender}`}
                    title={`avatar ${review.gender}`}
                  />
                  <div className={styles.infoPerson}>
                    <p className={styles.personName}>{review.name}</p>
                    <div className={styles.personCountry}>
                      <img
                        className={styles.image}
                        alt={`${review.country} flag`}
                        title={`${review.country} flag`}
                        src={getFlagImg(review.country)}
                      />
                      {review.country}
                    </div>
                  </div>
                </div>
              </div>
              <p className={styles.content}>
                {review.text}
              </p>
            </li>
          ))}
        </ul>
        {this.state.limit < 12 &&
          <div className={styles.buttonWrapper}>
            <Button
              size="showMore"
              onClick={this.handleShowMore}
            >
              {this.props.buttonLabel}
            </Button>
          </div>
        }
      </div>
    );
  }
}

Reviews.defaultProps = {
  buttonLabel: "READ MORE REVIEWS",
  reviews: defaultReviews,
  limit: 4,
}

export default Reviews;
