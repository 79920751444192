import { useState, useEffect } from 'react';

const getData = endTime => {
  const timeLeft = (endTime - new Date()) / 1000;
  const days = Math.floor(timeLeft / 86400);
  const hours = Math.floor((timeLeft - days * 86400) / 3600);
  const minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
  const seconds = Math.floor((timeLeft - days * 86400 - hours * 3600 - minutes * 60));

  return {
    days,
    hours,
    minutes,
    seconds,
    expired: timeLeft < 0,
  }
}

export function useTimer(endTime) {
  const [data, setData] = useState(getData(endTime));

  useEffect(() => {
    setInterval(() => {
      setData(getData(endTime))
    }, 1000);
  }, []);

  return data;
}
