import React from "react"
import lifetimeAccessIcon from "./img/lifetime-access.svg"
import mobileAppIcon from "./img/mobile-app.svg"
import moneybackGuaranteeIcon from "./img/moneyback-guarantee.svg"
import styles from "./styles.scss"
import Tooltip from "../tooltip/Tooltip"
import Row from "../Row"

function MasterclassOptionsIcons ({...rest}) {
    return (
      <Row {...rest}>
        <div className="masterclass-options__item text-center">
          <img src={lifetimeAccessIcon} alt="lifetime access"/>
          <h4>Lifetime access</h4>
        </div>
        <div className="masterclass-options__item text-center">
          <img src={mobileAppIcon} alt="learn on the go with mobile app"/>
          <h4>Learn on the go with mobile app <span style={{ fontWeight: 'normal' }}>(iOS)</span></h4>
        </div>
        <div className="masterclass-options__item text-center">
          <img src={moneybackGuaranteeIcon} alt="money-back-guarantee"/>
          <h4>
            Money-back guarantee
            <Tooltip
              content={
                <span>
                    If you don’t like the Masterclass let us know at <a
                  className="tooltip-link"
                  href="mailto:moneyback@mindworxacademy.com"
                  style={{ color: "#FF1A2A" }}
                >moneyback@mindworxacademy.com</a> and with one click we’ll send all your money back, no questions asked. An immediate 14-day money-back guarantee to all our students is also a condition of Teachable - the platform on which this Masterclass was built.
                  </span>
              }
            >
                <span
                  className="masterclass-options__info-icon"
                />
            </Tooltip>
          </h4>
        </div>
      </Row>
    )
}

export default MasterclassOptionsIcons
