import styles from "../navbar/navbar.module.scss"
import React from "react"
import { useWindowDimensions } from "../../helpers/window"

const NavigationItem = ({
  children,
  onToggleMenu,
  style,
  ...rest
}) => {
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth < 1024;

  return (
    <li
      className={styles.anchorLink}
      onClick={isMobile ? onToggleMenu : () => {}}
      style={style}
      {...rest}
    >
      {children}
    </li>
  )
}

export default NavigationItem;
