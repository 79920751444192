import styles from "../navbar/navbar.module.scss"
import React from "react"

const NavigationItems = ({children}) => {
  return (
    <ul className={styles.navList}>
      {children}
    </ul>
  )
}

export default NavigationItems;
