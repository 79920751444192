import React, { useState } from "react"
import classnames from "../../helpers/classnames"
import styles from "./styles.module.scss"
import { useTimer } from "../../helpers/timer"
import { pad } from "../../helpers/format"
import { scrollById } from "../../utils/scroller"

const DiscountBanner = ({
  text,
  expires,
  formatted=false,
  ...rest
}) => {
  const {
    days,
    hours,
    minutes,
    seconds,
    expired
  } = useTimer(new Date(expires));

  const [show, setShow] = useState(1);

  const getText = () => {
    return text
      .replace("%d", formatted ? pad(days, 2) : days)
      .replace("%h", formatted ? pad(hours, 2) : hours)
      .replace("%m", formatted ? pad(minutes, 2) : minutes)
      .replace("%s", formatted ? pad(seconds, 2) : seconds)
  }

  const close = e => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
  }

  return (
    <>
      {show && !expired &&
        <div
          onClick={() => { scrollById('pricing-complete-masterclass') }}
          className={classnames(
            styles.discountBanner
          )}
          {...rest}
        >
          <div
            dangerouslySetInnerHTML={{__html: getText()}}
          />
          <div
            className={classnames(
              styles.close
            )}
            onClick={close}
          >⨉</div>
        </div>
      }
    </>
  )
}

export default DiscountBanner
