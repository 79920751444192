import React, { useState } from "react"
import Popup from "../../popup"
import modalStyles from "../../popup/popup.module.scss"
import Button from "../../button"

export function FreemiumPopup({
                                show,
                                handleClose,
                                handleAfterSubmit,
                                ...rest
                              }) {
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false)

  const handleFormSubmit = e => {
    e.preventDefault()
    handleAfterSubmit()
  }

  return (
    <Popup
      show={show}
      handleClose={handleClose}
      {...rest}
    >
      <h2 className={modalStyles.title}>Your 7-day Free trial is ready!</h2>
      <p className={modalStyles.text}>
        All it takes is to create a free account.
        Don't worry, it's just a formality so that you can access your free lectures.
      </p>
      <form
        method="post"
        onSubmit={handleFormSubmit}
        className={modalStyles.form}
      >

        <div className={modalStyles.checkboxWrapper}>
          <input
            type="checkbox"
            className={modalStyles.checkboxInput}
            id="freemium-popup-checkbox"
            onChange={() => setIsPolicyAccepted(!isPolicyAccepted)}
          />

          <label
            htmlFor="freemium-popup-checkbox"
            style={{
              fontSize: "12px",
              paddingLeft: "28px",
              fontWeight: "400"
            }}
          >
            I agree to the
            <a target="_blank" className={modalStyles.link} href={"/privacy-policy"}>
              privacy policy
            </a>
            {" "}and to receive instructional and occasional promotional emails.
          </label>
        </div>

        <Button
          id="cta-popup-freemium-sign-in"
          size="popup"
          type="submit"
          disabled={!isPolicyAccepted}
        >GET ME GOING</Button>

      </form>
    </Popup>
  )
}

export default FreemiumPopup
